import React from "react"
import styled from "styled-components"

import { Layout } from "../components/layout"

import PageHeading from "../components/PageHeading/PageHeading"

import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import { Container } from "@material-ui/core"
import { InstagramFeed } from "../components/InstagramFeed"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/about-us",
  },
]

const AboutUs: React.FC = ({ data }) => {
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)
  return (
    <Layout title={"Graduate Fashion Week - About Us"}>
      <PageHeading breadcrumbData={breadcrumbData} pageTitle="ABOUT US" />

      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
      <PortfoliosContainer></PortfoliosContainer>
      <InstagramFeed />
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query aboutUs {
    wpPage(title: { eq: "About Us" }) {
      id
      lazy_data
      title
    }
  }
`

const PortfoliosContainer = styled.div`
  width: 100vw;
  max-width: 1200px;
  justify-content: center;
  z-index: 10;
  margin-bottom: 80px;
`
